import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument';
/**
 * Determine if the current bar is the first one of the intraday.
 *
 * @param this
 * @returns
 */
export function isIntradayFirstBar() {
    return this.isSessionFirstBarV3(fr_instrument.getSessions(this._context.symbol.ticker).at(0));
}
