import { fr_instrument } from '~/pages/heineken_template/_fr/fr_instrument';
/**
 * Determine if the current bar is the last one of the intraday.
 *
 * @param this
 * @returns
 */
export function isIntradayLastBar() {
    return this.isSessionLastBarV2(fr_instrument.getSessions(this._context.symbol.ticker).at(-1));
}
